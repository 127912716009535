import * as React from "react"
import { SVGProps } from "react"

const BonusIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_7049_53011)">
			<rect
				x="4.86227"
				y="11.7649"
				width="14.2757"
				height="8.9223"
				rx="0.939189"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<path
				d="M7.30426 5.66023C7.30426 6.95698 8.35548 8.0082 9.65223 8.0082H12.0002V5.66023C12.0002 4.36348 10.949 3.31226 9.65223 3.31226C8.35548 3.31226 7.30426 4.36348 7.30426 5.66023Z"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<path
				d="M16.696 5.66023C16.696 6.95698 15.6448 8.0082 14.348 8.0082H12.0001V5.66023C12.0001 4.36348 13.0513 3.31226 14.348 3.31226C15.6448 3.31226 16.696 4.36348 16.696 5.66023Z"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<rect
				x="2.60831"
				y="8.0083"
				width="18.7838"
				height="3.75676"
				rx="0.939189"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<path
				d="M12.0002 8.0083V19.6895"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_7049_53011">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0 -0.000244141)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default BonusIcon
