import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { pagesLinks, pagesLinksKz} from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			pageName: "Маркетинг и CRM",
			desktopTitle: "Чтобы гости \nвозвращались",
			tabletTitle: "Чтобы гости возвращались",
			mobileTitle: "Чтобы гости возвращались",
			subTitle: (
				<>
					<p className={styles.subtitle__mobileDesk}>
						Акции, бонусные программы,&nbsp;персональные скидки, промо-купоны
						и&nbsp;даже больше. С&nbsp;такими инструментами любой гость
						почувствует заботу и убедится в своей выгоде —&nbsp;значит, будет возвращаться снова и&nbsp;снова.
					</p>
					<p className={styles.subtitle__mobile}>
						Акции, бонусные программы
						и&nbsp;персональные скидки, промо-купоны
						и&nbsp;даже больше. С&nbsp;такими инструментами CRM любой гость почувствует заботу, выгоду и&nbsp;будет возвращаться снова
						и&nbsp;снова.
					</p>
				</>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					src="./assets/marketing-presentation_kz.png"
					alt="программы лояльности в ресторане"
					breakpoints={[500, 800]}
					sizes="(max-width: 500px) 400px,  (min-width: 501px) 800px"
					className={styles.image}
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	if (locale === 'kz-KZ') {
		return {
			pageName: "Маркетинг және CRM",
			desktopTitle: "Қонақтар оралуы\nүшін",
			tabletTitle: "Қонақтар оралуы үшін",
			mobileTitle: "Қонақтар оралуы үшін",
			subTitle: (
				<>
					<p className={styles.subtitle__mobileDesk}>
						Акциялар, бонустық бағдарламалар, дербес жеңілдіктер, промо-купондар және тіпті одан да көп.
						Мұндай құралдармен кез келген қонақ қамқорлықты сезінеді және өз пайдасына сенеді - демек,
						қайта оралатын болады.
					</p>
					<p className={styles.subtitle__mobile}>
						Акциялар, бонустық бағдарламалар, дербес жеңілдіктер, промо-купондар және тіпті одан да көп.
						Мұндай құралдармен кез келген қонақ қамқорлықты сезінеді және өз пайдасына сенеді - демек,
						қайта оралатын болады.
					</p>
				</>
			),
			cta: pagesLinksKz.registration.text,
			desktopCtaDetails:
				"Тез, ыңғайлы және екі апта тегін",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					src="./assets/marketing-presentation_kz.png"
					alt="программы лояльности в ресторане"
					breakpoints={[500, 800]}
					sizes="(max-width: 500px) 400px,  (min-width: 501px) 800px"
					className={styles.image}
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	if (locale === 'ru-BY') {
		return {
			pageName: "Маркетинг и CRM",
			desktopTitle: "Чтобы все гости \nвозвращались",
			tabletTitle: "Чтобы все гости возвращались",
			mobileTitle: "Чтобы все гости возвращались",
			subTitle: (
				<>
					Акции, бонусные программы и&nbsp;персональные скидки, промо-купоны
					и&nbsp;даже больше. С&nbsp;такими инструментами CRM любой гость
					почувствует заботу, выгоду и&nbsp;будет возвращаться снова и&nbsp;снова.
				</>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					src="./assets/marketing-presentation_by.png"
					alt="программы лояльности в ресторане"
					breakpoints={[500, 800]}
					sizes="(max-width: 500px) 400px,  (min-width: 501px) 800px"
					className={styles.image}
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	return {
		pageName: "Маркетинг и CRM",
		desktopTitle: "Всё для того, чтобы гость вернулся",
		subTitle: (
			<>
				Акции, бонусные программы и&nbsp;персональные скидки, промо-купоны
				и&nbsp;даже больше. С&nbsp;такими инструментами CRM любой гость
				почувствует заботу, выгоду и&nbsp;будет возвращаться снова и&nbsp;снова.
			</>
		),
		cta: pagesLinks.registration.text,
		desktopCtaDetails:
			"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две \nнедели бесплатно.",
		ctaLink: pagesLinks.registration,
		figure: (
			<StaticImage
				src="./assets/marketing-presentation.png"
				alt="программы лояльности в ресторане"
				breakpoints={[500, 800]}
				sizes="(max-width: 500px) 400px,  (min-width: 501px) 800px"
				className={styles.image}
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
	}
}
