import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import mediaContent from "./mediaContent.module.scss";
import styles from "./styles.module.scss";
import Backdrop from "./assets/Backdrop";
import { pagesLinks } from "../../../pages-data/_V2/common/links";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Аналитика",
					desktopTitle: "Win-Win-Win лояльность",
					desktopContent:
						"Отчёт по эффективности акций покажет, какие механизмы системы лояльности нравятся гостям и приносят прибыль бизнесу.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/marketing-channels-1_kz.png"
							alt="Всё под контролем"
							breakpoints={[440, 670, 790, 630]}
							sizes="(max-width: 500px) 440px, (max-width: 800px) 670px, (max-width: 1319px) 790px, (min-width: 1320px) 630px"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,

					detailLink: pagesLinks.reports,
				},
				{
					desktopContentWidth: 490,
					tabName: "Каналы",
					desktopTitle: "Предложи больше",
					desktopContent:
						"Напоминай о бонусах через приложение. \nСобери отчет по акциям и пользователям, \nнастрой периодичность уведомлений.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/marketing-channels-2_kz.png"
							alt="Распределять нагрузку"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,

					detailLink: pagesLinks.reports,
				},
			],
		};
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Аналитика",
					desktopTitle: "Win-Win-Win адалдық",
					desktopContent:
						"Акциялар тиімділігі жөніндегі есеп адалдық жүйесінің қандай тетіктері қонақтарға ұнайтынын және бизнеске пайда әкелетінін көрсетеді.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/marketing-channels-1_kz.png"
							alt="Всё под контролем"
							breakpoints={[440, 670, 790, 630]}
							sizes="(max-width: 500px) 440px, (max-width: 800px) 670px, (max-width: 1319px) 790px, (min-width: 1320px) 630px"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,

					detailLink: pagesLinks.reports,
				},
				{
					desktopContentWidth: 490,
					tabName: "Арналар",
					desktopTitle: "Көбірек ұсыныңыз",
					desktopContent:
						"Қосымша арқылы бонустар туралы естеріне салыңыз  Акциялар мен пайдаланушылар бойынша есепті жинаңыз, хабарламалар кезеңділігін теңшеңіз.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/marketing-channels-2_kz.png"
							alt="Распределять нагрузку"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,

					detailLink: pagesLinks.reports,
				},
			],
		};
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Аналитика",
					desktopTitle: "Все в плюсе",
					desktopContent:
						"Отчёт по эффективности акций покажет какие механизмы системы лояльности нравятся гостям и приносят прибыль бизнесу.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/marketing-channels-1_by.png"
							alt="Всё под контролем"
							breakpoints={[440, 670, 790, 630]}
							sizes="(max-width: 500px) 440px, (max-width: 800px) 670px, (max-width: 1319px) 790px, (min-width: 1320px) 630px"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,

					detailLink: pagesLinks.reports,
				},
				{
					desktopContentWidth: 490,
					tabName: "Каналы",
					desktopTitle: "Предложи больше",
					desktopContent:
						"Напоминай о бонусах через приложение. \nСобери отчет по акциям и пользователям, настрой периодичность уведомлений.",
					tabletContent: (
						<>
							Напоминай о&nbsp;бонусах через приложение. <br />
							Собери отчет по&nbsp;акциям и&nbsp;пользователям, настрой
							периодичность уведомлений.
						</>
					),
					mobileContent: (
						<>
							Напоминай о&nbsp;бонусах через приложение. <br />
							Собери отчет по&nbsp;акциям и&nbsp;пользователям, настрой периодичность уведомлений.
						</>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/marketing-channels-2_by.png"
							alt="Распределять нагрузку"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,

					detailLink: pagesLinks.reports,
				},
			],
		};
	}

	return {
		features: [
			{
				desktopContentWidth: 490,
				tabName: "Аналитика",
				desktopTitle: "Win-Win-Win лояльность",
				desktopContent:
					"Отчёт по эффективности акций покажет какие механизмы системы лояльности нравятся гостям и приносят прибыль бизнесу.",
				mediaContent: (
					<StaticImage
						className={mediaContent.image1}
						src="./assets/marketing-channels-1.png"
						alt="Всё под контролем"
						breakpoints={[440, 670, 790, 630]}
						sizes="(max-width: 500px) 440px, (max-width: 800px) 670px, (max-width: 1319px) 790px, (min-width: 1320px) 630px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				backdropContent: <Backdrop className={styles.backdrop} />,

				detailLink: pagesLinks.reports,
			},
			{
				desktopContentWidth: 490,
				tabName: "Каналы",
				desktopTitle: "Увеличь количество заказов",
				desktopContent:
					"Напоминай о бонусах через приложение. \nСобери отчет по акциям и пользователям, настрой периодичность уведомлений.",
				mediaContent: (
					<StaticImage
						className={mediaContent.image2}
						src="./assets/marketing-channels-2.png"
						alt="Распределять нагрузку"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				backdropContent: <Backdrop className={styles.backdrop} />,

				detailLink: pagesLinks.reports,
			},
		],
	};
};
