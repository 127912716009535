import * as React from "react"
import { SVGProps } from "react"

const ComboIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_7049_53043)">
			<path
				d="M13.625 18.7373C13.625 16.6317 15.3319 14.9248 17.4375 14.9248V12.9248C14.2273 12.9248 11.625 15.5271 11.625 18.7373H13.625ZM17.4375 14.9248C19.5431 14.9248 21.25 16.6317 21.25 18.7373H23.25C23.25 15.5271 20.6477 12.9248 17.4375 12.9248V14.9248ZM11.625 18.7373V20.6623H13.625V18.7373H11.625ZM13.5875 22.6248H21.2875V20.6248H13.5875V22.6248ZM23.25 20.6623V18.7373H21.25V20.6623H23.25ZM21.2875 22.6248C22.3714 22.6248 23.25 21.7462 23.25 20.6623H21.25C21.25 20.6416 21.2668 20.6248 21.2875 20.6248V22.6248ZM11.625 20.6623C11.625 21.7462 12.5036 22.6248 13.5875 22.6248V20.6248C13.6082 20.6248 13.625 20.6416 13.625 20.6623H11.625Z"
				fill="#7D828C"
			/>
			<path
				d="M16.3448 17.7749H22.0484"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M3.96249 7.18726V6.18726C3.67423 6.18726 3.39999 6.31165 3.21011 6.52853C3.02023 6.74541 2.93317 7.03369 2.97127 7.31942L3.96249 7.18726ZM15.5125 7.18726L16.5037 7.31942C16.5418 7.03369 16.4548 6.74541 16.2649 6.52853C16.075 6.31165 15.8008 6.18726 15.5125 6.18726V7.18726ZM5.88749 21.6248L4.89627 21.7569C4.96251 22.2537 5.38629 22.6248 5.88749 22.6248V21.6248ZM9.73749 22.6248C10.2898 22.6248 10.7375 22.177 10.7375 21.6248C10.7375 21.0725 10.2898 20.6248 9.73749 20.6248V22.6248ZM14.04 10.6645C13.967 11.2119 14.3516 11.7149 14.8991 11.7879C15.4465 11.8609 15.9495 11.4762 16.0225 10.9288L14.04 10.6645ZM3.96249 8.18726H15.5125V6.18726H3.96249V8.18726ZM6.87872 21.4926L4.95372 7.05509L2.97127 7.31942L4.89627 21.7569L6.87872 21.4926ZM9.73749 20.6248H5.88749V22.6248H9.73749V20.6248ZM14.5213 7.05509L14.04 10.6645L16.0225 10.9288L16.5037 7.31942L14.5213 7.05509Z"
				fill="#7D828C"
			/>
			<path
				d="M3 7.18726H16.475"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M8.68861 7.17386C8.68861 7.72615 9.13633 8.17386 9.68861 8.17386C10.2409 8.17386 10.6886 7.72615 10.6886 7.17386H8.68861ZM4.92499 1.37476C4.3727 1.37476 3.92499 1.82247 3.92499 2.37476C3.92499 2.92704 4.3727 3.37476 4.92499 3.37476V1.37476ZM10.6886 7.17386V4.29976H8.68861V7.17386H10.6886ZM7.76361 1.37476H4.92499V3.37476H7.76361V1.37476ZM10.6886 4.29976C10.6886 2.68432 9.37904 1.37476 7.76361 1.37476V3.37476C8.27447 3.37476 8.68861 3.78889 8.68861 4.29976H10.6886Z"
				fill="#7D828C"
			/>
		</g>
		<defs>
			<clipPath id="clip0_7049_53043">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0 -0.000244141)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default ComboIcon
