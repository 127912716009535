import * as React from "react"
import { SVGProps } from "react"

const DiscountIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_7049_52963)">
			<g clipPath="url(#clip1_7049_52963)">
				<path
					d="M7.8329 16.1668L16.1666 7.83301"
					stroke="#7D828C"
					strokeWidth="2"
					strokeLinecap="round"
				/>
				<circle
					cx="8.66656"
					cy="9.50006"
					r="1.66656"
					stroke="#7D828C"
					strokeWidth="2"
				/>
				<circle
					cx="15.3334"
					cy="14.4997"
					r="1.66656"
					transform="rotate(-180 15.3334 14.4997)"
					stroke="#7D828C"
					strokeWidth="2"
				/>
				<path
					d="M10.9342 1.92581C11.5693 1.46256 12.4308 1.46256 13.0659 1.92581L14.3725 2.87894C14.683 3.10541 15.0575 3.2271 15.4418 3.22637L17.0591 3.22329C17.8452 3.22179 18.5422 3.72821 18.7837 4.47627L19.2806 6.01539C19.3986 6.3811 19.6301 6.69969 19.9414 6.92498L21.2517 7.87313C21.8885 8.33396 22.1547 9.15336 21.9104 9.90049L21.4077 11.4377C21.2883 11.803 21.2883 12.1968 21.4077 12.562L21.9104 14.0992C22.1547 14.8464 21.8885 15.6658 21.2517 16.1266L19.9414 17.0748C19.6301 17.3 19.3986 17.6186 19.2806 17.9843L18.7837 19.5235C18.5422 20.2715 17.8452 20.7779 17.0591 20.7764L15.4418 20.7734C15.0575 20.7726 14.683 20.8943 14.3725 21.1208L13.0659 22.0739C12.4308 22.5372 11.5693 22.5372 10.9342 22.0739L9.62757 21.1208C9.3171 20.8943 8.94257 20.7726 8.55828 20.7734L6.94095 20.7764C6.15488 20.7779 5.45786 20.2715 5.21637 19.5235L4.71952 17.9843C4.60147 17.6186 4.36999 17.3 4.05866 17.0748L2.74841 16.1266C2.11159 15.6658 1.84535 14.8464 2.08968 14.0992L2.59239 12.562C2.71184 12.1968 2.71184 11.803 2.59239 11.4377L2.08968 9.90049C1.84535 9.15336 2.11159 8.33396 2.74841 7.87313L4.05866 6.92498C4.36999 6.69969 4.60147 6.3811 4.71952 6.01539L5.21637 4.47627C5.45786 3.72821 6.15489 3.22179 6.94095 3.22329L8.55828 3.22637C8.94257 3.2271 9.3171 3.10541 9.62757 2.87894L10.9342 1.92581Z"
					stroke="#7D828C"
					strokeWidth="2"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_7049_52963">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0 -0.000244141)"
				/>
			</clipPath>
			<clipPath id="clip1_7049_52963">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0 -0.000244141)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default DiscountIcon
