import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import { pagesLinks } from "../common/links"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				title: "Электронное меню",
				description: (
					<p>
						Онлайн-меню на случай, когда нет возможности воспользоваться бумажной
						версией. Всегда актуальные позиции благодаря обмену данными внутри
						экосистемы Quick Resto.
					</p>
				),

				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/marketing-feature-1_kz.png"
						alt="программа автоматизации фастфуда"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.feature1,
				theme: "purple",

				actionText: "Подробнее",
				actionLink: pagesLinks.wlShop.href,
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				title: "Электрондық мәзірлер",
				description: (
					<p>
						Қағаз нұсқасын пайдалану мүмкіндігі болмаған жағдайға арналған онлайн-мәзір. Quick Resto экожүйесінің ішіндегі деректер алмасудың арқасында әрқашан өзекті позициялар.
					</p>
				),

				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/marketing-feature-1_kz.png"
						alt="программа автоматизации фастфуда"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.feature1,
				theme: "purple",

				actionText: "Толығырақ",
				actionLink: pagesLinks.wlShop.href,
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				title: "Электронное меню",
				description: (
					<p>
						Онлайн-меню на случай, когда нет возможности воспользоваться бумажной
						версией. Всегда актуальные позиции благодаря обмену данными внутри
						экосистемы Quick Resto.
					</p>
				),

				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/marketing-feature-1_by.png"
						alt="программа автоматизации фастфуда"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.feature1,
				theme: "purple",

				actionText: "Подробнее",
				actionLink: pagesLinks.wlShop.href,
			},
		]
	}

	return [
		{
			title: "Электронное меню",
			description: (
				<p>
					Онлайн меню на случай, когда нет возможности воспользоваться бумажной
					версией. Всегда актуальные позиции благодаря обмену данными внутри
					экосистемы Quick Resto.
				</p>
			),

			img: (
				<StaticImage
					className={styles.image1}
					src="./assets/marketing-feature-1.png"
					alt="программа автоматизации фастфуда"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),

			className: styles.feature1,
			theme: "purple",

			actionText: "Подробнее",
			actionLink: pagesLinks.wlShop.href,
		},
	]
}
