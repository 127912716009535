import React from "react"

import TabsFeatures from "../../../components/_V2/TabsFeatures"

import { getData } from "./data"
import styles from "./styles.module.scss"
import { useLocalizationContext } from "../../../localization/useLocalizationContext"

interface Props {
	className?: string
}

export default function SalesFeatures(props: Props) {
	const localizationContext = useLocalizationContext();

	return (
		<TabsFeatures
			key={localizationContext.locale}
			data={getData(localizationContext.locale)}
			className={props.className}
			rootClass={styles.salesFeatures}
			containerClass={styles.salesFeatures__container}
			wrapperClass={styles.salesFeatures__wrapper}
			isTabsInColumns={true}
			theme={"blue-sky"}
		/>
	)
}
