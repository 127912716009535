import React from "react"
import classNames from "classnames"

import FullScreenFeatures from "../../../components/_V2/FullScreenFeatures"

import { getData } from "./data"
import styles from "./styles.module.scss"
import { useLocalizationContext } from "../../../localization/useLocalizationContext"

interface Props {
	className?: string
}

export default function CRMFeatures(props: Props) {
	const localizationContext = useLocalizationContext();

	return (
		<FullScreenFeatures
			key={localizationContext.locale}
			className={classNames(styles.section, props.className)}
			data={getData(localizationContext.locale).features}
			theme={"greenWarm"}
		/>
	)
}
