import { Locales } from "../../../localization/types";
import { externalLinks } from "../../../pages-data/_V2/common/links";
import Prez from "../ProductPresentation/assets/marketing-presentation.png";
import PrezKz from "../ProductPresentation/assets/marketing-presentation_kz.png";
import PrezBy from "../ProductPresentation/assets/marketing-presentation_by.png";

export const getMarketingMeta = (locale: Locales) => {
	switch (locale) {
		case "ru-KZ":
			return {
				title: "CRM-система и программы лояльности для ресторанов и кафе | Quick Resto Казахстан",
				metaTags: [
					{
						name: "description",
						content:
							"CRM-система для ресторанов и кафе от Quick Resto → Программы лояльности гостей → Акции, бонусные программы, персональные скидки → Все инструменты для того, чтобы гость вернулся. Автоматизируй бизнес в Казахстане",
					},
					{
						property: "og:url",
						content: `${externalLinks.quickrestoKZ.href}marketing/`,
					},
					{
						property: "og:title",
						content:
							"CRM-система и программы лояльности для ресторанов и кафе | Quick Resto Казахстан",
					},
					{
						property: "og:description",
						content:
							"CRM-система для ресторанов и кафе от Quick Resto → Программы лояльности гостей → Акции, бонусные программы, персональные скидки → Все инструменты для того, чтобы гость вернулся. Автоматизируй бизнес в Казахстане",
					},
					{
						property: "og:type",
						content: "website",
					},
					{
						property: "og:image",
						content:
							`${externalLinks.quickrestoKZ.href}${PrezKz}`,
					},
				],
				canonical: `${externalLinks.quickrestoKZ.href}marketing/`,
			};
		case "ru-BY":
			return {
				title: "CRM-система и программы лояльности для ресторанов и кафе | Quick Resto Беларусь",
				metaTags: [
					{
						name: "description",
						content:
							"CRM-система для ресторанов и кафе от Quick Resto → Программы лояльности гостей → Акции, бонусные программы, персональные скидки → Всё инструменты для того, чтобы гость вернулся. Автоматизируй бизнес в Беларуси",
					},
					{
						property: "og:url",
						content: `${externalLinks.quickrestoBY.href}marketing/`,
					},
					{
						property: "og:title",
						content:
							"CRM-система и программы лояльности для ресторанов и кафе | Quick Resto Беларусь",
					},
					{
						property: "og:description",
						content:
							"CRM-система для ресторанов и кафе от Quick Resto → Программы лояльности гостей → Акции, бонусные программы, персональные скидки → Всё инструменты для того, чтобы гость вернулся. Автоматизируй бизнес в Беларуси",
					},
					{
						property: "og:type",
						content: "website",
					},
					{
						property: "og:image",
						content:
							`${externalLinks.quickrestoBY.href}${PrezBy}`,
					},
				],
				canonical: `${externalLinks.quickrestoBY.href}marketing/`,
			};
		default:
			return {
				title: "CRM-система и программы лояльности для ресторанов и кафе | Quick Resto",
				metaTags: [
					{
						name: "description",
						content:
							"CRM-система для ресторанов и кафе от Quick Resto  → Программы лояльности гостей → Акции, бонусные программы, персональные скидки → Всё инструменты для того, чтобы гость вернулся",
					},
					{
						property: "og:url",
						content: `${externalLinks.quickrestoRU.href}marketing/`,
					},
					{
						property: "og:title",
						content:
							"CRM-система и программы лояльности для ресторанов и кафе | Quick Resto",
					},
					{
						property: "og:description",
						content:
							"CRM-система для ресторанов и кафе от Quick Resto  → Программы лояльности гостей → Акции, бонусные программы, персональные скидки → Всё инструменты для того, чтобы гость вернулся",
					},
					{
						property: "og:type",
						content: "website",
					},
					{
						property: "og:image",
						content:
							"https://quickresto.ru/static/42fc84a825a9be178eb41d7bcebdd572/e88ed/presentation.webp",
					},
				],
				canonical: `${externalLinks.quickrestoRU.href}marketing/`,
			};
	}
};
