import * as React from "react"
import { SVGProps } from "react"

const ClientGroupIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_7049_52997)">
			<circle
				cx="12.2192"
				cy="6.49976"
				r="2.5"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<path
				d="M7.97234 10.4727C7.69951 10.9529 7.8676 11.5634 8.34778 11.8362C8.82797 12.109 9.43841 11.9409 9.71125 11.4608L7.97234 10.4727ZM14.7274 11.4608C15.0002 11.9409 15.6106 12.109 16.0908 11.8362C16.571 11.5634 16.7391 10.9529 16.4663 10.4727L14.7274 11.4608ZM9.71125 11.4608C10.2082 10.5861 11.1459 9.99976 12.2193 9.99976V7.99976C10.3975 7.99976 8.81022 8.99809 7.97234 10.4727L9.71125 11.4608ZM12.2193 9.99976C13.2927 9.99976 14.2304 10.5861 14.7274 11.4608L16.4663 10.4727C15.6284 8.99809 14.0411 7.99976 12.2193 7.99976V9.99976Z"
				fill="#7D828C"
			/>
			<circle
				cx="6.59668"
				cy="15.533"
				r="2.5"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<path
				d="M2.34978 19.5059C2.07695 19.9861 2.24504 20.5966 2.72522 20.8694C3.20541 21.1422 3.81586 20.9741 4.08869 20.494L2.34978 19.5059ZM9.1048 20.494C9.37764 20.9741 9.98808 21.1422 10.4683 20.8694C10.9485 20.5966 11.1165 19.9861 10.8437 19.5059L9.1048 20.494ZM4.08869 20.494C4.58566 19.6193 5.52336 19.033 6.59675 19.033V17.033C4.77498 17.033 3.18766 18.0313 2.34978 19.5059L4.08869 20.494ZM6.59675 19.033C7.67013 19.033 8.60783 19.6193 9.1048 20.494L10.8437 19.5059C10.0058 18.0313 8.41851 17.033 6.59675 17.033V19.033Z"
				fill="#7D828C"
			/>
			<circle
				cx="17.8418"
				cy="15.533"
				r="2.5"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<path
				d="M13.5949 19.5059C13.3221 19.9861 13.4902 20.5966 13.9703 20.8694C14.4505 21.1422 15.061 20.9741 15.3338 20.494L13.5949 19.5059ZM20.3499 20.494C20.6228 20.9741 21.2332 21.1422 21.7134 20.8694C22.1936 20.5966 22.3617 19.9861 22.0888 19.5059L20.3499 20.494ZM15.3338 20.494C15.8308 19.6193 16.7685 19.033 17.8419 19.033V17.033C16.0201 17.033 14.4328 18.0313 13.5949 19.5059L15.3338 20.494ZM17.8419 19.033C18.9152 19.033 19.853 19.6193 20.3499 20.494L22.0888 19.5059C21.251 18.0313 19.6636 17.033 17.8419 17.033V19.033Z"
				fill="#7D828C"
			/>
		</g>
		<defs>
			<clipPath id="clip0_7049_52997">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0 -0.000244141)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default ClientGroupIcon
