import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types"
import classNames from "classnames";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					tabName: "Поиск гостей",
					desktopTitle: "Гостю приятно, когда его узнают",
					mobileTitle: "Гостю приятно, когда его узнают",
					desktopContent:
						"Найди гостя по любому ключевому запросу: имя, телефон, карта, номер заказа.\n Еще быстрее — через QR или штрихкод в мобильном приложении и с карты лояльности.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.image1}
								src="./assets/marketing-crm-1_kz.png"
								alt={"Доставка для ресторанного бизнеса"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.bg1} />,
				},
				{
					tabName: "Добавление в CRM",
					desktopTitle: "Расширяй базу и увеличивай прибыль",
					desktopContent:
						"Зарегистрируй гостя через бэк-офис, терминал или QR-код с карты лояльности. \n Либо предложи сделать это самостоятельно: через приложение и сайт.",
					mediaContent: (
						<StaticImage
							className={styles.image2}
							src="./assets/marketing-crm-2_kz.png"
							alt={"Карта столов для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.bg2} />,
				},
				{
					tabName: "Сегментация",
					desktopTitle: "Посмотри, кто к нам пришёл!",
					desktopContent:
						"Сегментируй гостей по среднему чеку, количеству и частоте визитов. \n Увеличивай лояльность с помощью персональных предложений.",
					mediaContent: (
						<StaticImage
							className={styles.image3}
							src="./assets/marketing-crm-3_kz.png"
							alt={"Модификаторы блюд в Quick Resto"}
							objectFit={"contain"}
							placeholder={"blurred"}
						/>
					),
					backdropContent: <div className={styles.bg3} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					tabName: "Қонақтарды іздеу",
					desktopTitle: "Қонақты танығанда, ол үшін сүйсінерлік болады",
					mobileTitle: "Қонақты танығанда, ол үшін сүйсінерлік болады",
					desktopContent:
						"Қонақты кез келген негізгі сұрау бойынша табыңыз: аты, телефоны, картасы, тапсырыс нөмірі. Одан да тезірек - мобильді қосымшадағы QR немесе штрихкод арқылы немесе адалдық картасынан.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.image1}
								src="./assets/marketing-crm-1_kz.png"
								alt={"Доставка для ресторанного бизнеса"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.bg1} />,
				},
				{
					tabName: "CRM-ге қосу",
					desktopTitle: "Қорды кеңейтіңіз және пайданы ұлғайтыңыз",
					desktopContent:
						"Қонақты бэк-офис, терминал немесе адалдық картасынан QR-код арқылы тіркеңіз. Немесе бұны өз күшімен жасауды ұсыныңыз: қосымша және сайт арқылы.",
					mediaContent: (
						<StaticImage
							className={styles.image2}
							src="./assets/marketing-crm-2_kz.png"
							alt={"Карта столов для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.bg2} />,
				},
				{
					tabName: "Сегменттеу",
					desktopTitle: "Бізге кімнің келгенін қараңыз!",
					desktopContent:
						"Қонақтарды орташа чек, келулер саны мен жиілігі бойынша сегменттеңіз. \n Дербес ұсыныстар көмегімен адалдықты арттырыңыз.",
					mediaContent: (
						<StaticImage
							className={styles.image3}
							src="./assets/marketing-crm-3_kz.png"
							alt={"Модификаторы блюд в Quick Resto"}
							objectFit={"contain"}
							placeholder={"blurred"}
						/>
					),
					backdropContent: <div className={styles.bg3} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					tabName: "Поиск гостей",
					desktopTitle: "Знай каждого гостя",
					mobileTitle: "Знай каждого гостя",
					desktopContent:
						"Найди гостя по любому ключевому запросу: имя, телефон, карта, номер заказа.\nЕще быстрее — через QR или штрихкод в мобильном приложении и с карты лояльности.",
					mediaContent: (
						<>
							<StaticImage
								className={classNames(styles.image1, styles.by)}
								src="./assets/marketing-crm-1_by.png"
								alt={"Доставка для ресторанного бизнеса"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.bg1} />,
				},
				{
					tabName: "Добавление в CRM",
					desktopTitle: "Больше база — выше продажи",
					desktopContent:
						"Зарегистрируй гостя через бэк-офис, терминал, QR-код с карты лояльности. \nЛибо предложи сделать это самому: через мобильное приложение и сайт.",
					mediaContent: (
						<StaticImage
							className={styles.image2}
							src="./assets/marketing-crm-2_by.png"
							alt={"Карта столов для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.bg2} />,
				},
				{
					tabName: "Сегментация",
					desktopTitle: "Посмотри, кто к нам пришёл!",
					desktopContent:
						"Сегментируй гостей по среднему чеку, количеству и частоте визитов. \nУвеличивай лояльность с помощью персональных предложений.",
					mediaContent: (
						<StaticImage
							className={styles.image3}
							src="./assets/marketing-crm-3_by.png"
							alt={"Модификаторы блюд в Quick Resto"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.bg3} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		}
	}

	return {
		features: [
			{
				tabName: "Поиск гостей",
				desktopTitle: "Гостю приятно, когда его узнают",
				mobileTitle: "Гостю приятно, когда его узнают",
				desktopContent:
					"Найди гостя по любому ключевому запросу: имя, телефон, карта, номер заказа.\n Еще быстрее — через QR или штрихкод в мобильном приложении и с карты лояльности.",
				mediaContent: (
					<>
						<StaticImage
							className={styles.image1}
							src="./assets/marketing-crm-1.png"
							alt={"Доставка для ресторанного бизнеса"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</>
				),
				backdropContent: <div className={styles.bg1} />,
			},
			{
				tabName: "Добавление в CRM",
				desktopTitle: "Растёт база — растут продажи",
				desktopContent:
					"Зарегистрируй гостя через бэк-офис, терминал, QR-код с карты лояльности. \n Либо предложи сделать это самому: через мобильное приложение и сайт.",
				mediaContent: (
					<StaticImage
						className={styles.image2}
						src="./assets/marketing-crm-2.png"
						alt={"Карта столов для ресторана"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.bg2} />,
			},
			{
				tabName: "Сегментация",
				desktopTitle: "Посмотри, кто к нам пришёл!",
				desktopContent:
					"Сегментируй гостей по  среднему чеку, количеству и частоте визитов. \n Увеличивай лояльность с помощью персональных предложений.",
				mediaContent: (
					<StaticImage
						className={styles.image3}
						src="./assets/marketing-crm-3.png"
						alt={"Модификаторы блюд в Quick Resto"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.bg3} />,
			},
		] as Array<{
			tabName: string

			// Заголовки могут быть для разных раскладок
			desktopTitle: string
			tabletTitle?: string
			mobileTitle?: string

			// Текстовый контент может быть для разных
			desktopContent: string
			tabletContent?: string
			mobileContent?: string

			detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

			mediaContent: JSX.Element
			backdropContent: JSX.Element
		}>,
	}
}
