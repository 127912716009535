import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Locales } from "../../../localization/types";

export const getAdvantagesSlider = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				img: (
					<StaticImage
						src="./assets/m-advantages-1.png"
						alt="Без комиссии агрегаторов"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Единая база гостей",
				description:
					"Собирай и&nbsp;структурируй информацию и&nbsp;предпочтения гостей в&nbsp;единой базе заведения.",
			},
			{
				img: (
					<StaticImage
						src="./assets/m-advantages-2.png"
						alt="Всегда на связи с гостями"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Десяток способов напомнить о&nbsp;себе",
				description:
					"Настрой свой метод взаимодействия с&nbsp;гостями из&nbsp;множества инструментов системы.",
			},
			{
				img: (
					<StaticImage
						src="./assets/m-advantages-3.png"
						alt="Да, это бесплатно"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Найти инсайт",
				description:
					"Анализируй предпочтения гостей, чтобы предложить им&nbsp;любимый круассан или вовремя позвать на&nbsp;ланч.",
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				img: (
					<StaticImage
						src="./assets/m-advantages-1.png"
						alt="Без комиссии агрегаторов"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Барлық қонақтардың байланыс деректері бір қорда",
				description:
					"Ақпаратты және қонақтардың қалауларын мекеменің бірыңғай қорында жинаңыз және құрылымдаңыз.",
			},
			{
				img: (
					<StaticImage
						src="./assets/m-advantages-2.png"
						alt="Всегда на связи с гостями"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Қонақтарды жаңадай қызықтырып тартыңыз",
				description:
					"Жүйенің көптеген құралдарынан қонақтармен өзара әрекеттесудің өз әдісіңізді теңшеңіз.",
			},
			{
				img: (
					<StaticImage
						src="./assets/m-advantages-3.png"
						alt="Да, это бесплатно"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Өз қонағыңызды біліңіз",
				description:
					"Қонақтарға сүйікті круассандарын ұсыну немесе ланчқа уақытылы шақыру үшін олардың қалауларын талдаңыз.",
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				img: (
					<StaticImage
						src="./assets/m-advantages-1.png"
						alt="Без комиссии агрегаторов"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Контакты гостей <br/>всегда под рукой",
				description:
					"Собирай и&nbsp;структурируй информацию и&nbsp;предпочтения гостей&nbsp;в единой базе заведения.",
			},
			{
				img: (
					<StaticImage
						src="./assets/m-advantages-2.png"
						alt="Всегда на связи с гостями"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Десяток способов пригласить&nbsp;гостя",
				description:
					"Настрой свой метод взаимодействия&nbsp;с гостями из множества&nbsp;инструментов системы.",
			},
			{
				img: (
					<StaticImage
						src="./assets/m-advantages-3.png"
						alt="Да, это бесплатно"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Не угадывай, а знай",
				description:
					"Анализируй предпочтения гостей, чтобы предложить им&nbsp;любимый круассан или вовремя позвать на&nbsp;ланч.",
			},
		]
	}

	return [
		{
			img: (
				<StaticImage
					src="./assets/m-advantages-1.png"
					alt="Без комиссии агрегаторов"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Единая база гостей",
			description:
				"Собирай и&nbsp;структурируй информацию и&nbsp;предпочтения гостей в&nbsp;единой базе заведения.",
		},
		{
			img: (
				<StaticImage
					src="./assets/m-advantages-2.png"
					alt="Всегда на связи с гостями"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Десяток способов напомнить о&nbsp;себе",
			description:
				"Настрой свой метод взаимодействия с&nbsp;гостями из&nbsp;множества инструментов системы.",
		},
		{
			img: (
				<StaticImage
					src="./assets/m-advantages-3.png"
					alt="Да, это бесплатно"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Найти инсайт",
			description:
				"Анализируй предпочтения гостей, чтобы предложить им&nbsp;любимый круассан или вовремя позвать на&nbsp;ланч.",
		},
	]
}
