import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/marketing.module.scss'

import Advantages from '../components/_V2/Advantages'
import SimpleFeatures from '../components/_V2/SimpleFeatures'
import BusinessTypes from '../components/_V2/BusinessTypes'

import MarketingProductPresentation from '../pagesSections/marketing/ProductPresentation'
import CRMFeatures from '../pagesSections/marketing/CRMFeatures'
import AdditionalServices from '../pagesSections/index/AdditionalServices'
import CtaForm from '../pagesSections/index/CtaForm'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import MobileFeatures from '../pagesSections/index/MobileFeatures'
import ChannelFeatures from '../pagesSections/marketing/ChannelFeatures'
import SalesFeatures from '../pagesSections/marketing/SalesFeatures'
import { getMarketingMeta } from '../pagesSections/marketing/meta';

import { getAdvantagesSlider } from '../pages-data/_V2/marketing/advantages'
import { getData } from '../pages-data/_V2/marketing/features'
import { businessTypes } from '../pages-data/_V2/common/business-types-compact'
import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function MarketingPage() {
  const localizationContext = useLocalizationContext()

  const metaInfo = getMarketingMeta(localizationContext.locale)

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={metaInfo.title}
      metaTags={metaInfo.metaTags}
      linkCanonical={metaInfo.canonical}
      mainWrapperClassName={pageStyles.pageWrapper}
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={metaInfo.title}
      schemaDescription={metaInfo.metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaInfo.metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <MarketingProductPresentation />

      <Advantages
        items={getAdvantagesSlider(localizationContext.locale)}
        hideImages={false}
        className={classNames(
				  styles.marketing__advantages,
				  pageStyles.pageSection,
        )}
      />

      <CRMFeatures className={pageStyles.pageSection} />

      <SalesFeatures className={pageStyles.pageSection} />

      <ChannelFeatures className={pageStyles.pageSection} />

      <MobileFeatures className={pageStyles.pageSection} />

      <SimpleFeatures
        key={localizationContext.locale}
        data={getData(localizationContext.locale)}
        className={pageStyles.pageSection}
        isDisableLocaleClass
      />

      <AdditionalServices className={pageStyles.pageSection} />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <BusinessTypes key={localizationContext.locale} data={businessTypes(localizationContext.locale)} className={pageStyles.pageSection} />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
    </DefaultLayout>
  )
}
