import * as React from "react"
import { SVGProps } from "react"

const PromocodesIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_7049_53054)">
			<path
				d="M21.6098 9.2395V10.2395C22.1621 10.2395 22.6098 9.79179 22.6098 9.2395H21.6098ZM21.6098 14.7603L22.6098 14.7603C22.6098 14.208 22.1621 13.7603 21.6098 13.7603V14.7603ZM21.6098 17.5206H22.6098V17.5206L21.6098 17.5206ZM2.38974 14.7602L2.38968 13.7602C1.83742 13.7602 1.38974 14.2079 1.38974 14.7602H2.38974ZM2.38974 9.23934H1.38974C1.38974 9.7916 1.83742 10.2393 2.38968 10.2393L2.38974 9.23934ZM3.49391 4.37476C2.33181 4.37476 1.38974 5.31682 1.38974 6.47892H3.38974C3.38974 6.42139 3.43638 6.37476 3.49391 6.37476V4.37476ZM20.5056 4.37476H3.49391V6.37476H20.5056V4.37476ZM22.6098 6.47892C22.6098 5.31682 21.6677 4.37476 20.5056 4.37476V6.37476C20.5632 6.37476 20.6098 6.42139 20.6098 6.47892H22.6098ZM22.6098 9.2395V6.47892H20.6098V9.2395H22.6098ZM19.8494 11.9999C19.8494 11.0277 20.6375 10.2395 21.6098 10.2395V8.2395C19.533 8.2395 17.8494 9.9231 17.8494 11.9999H19.8494ZM21.6098 13.7603C20.6375 13.7603 19.8494 12.9722 19.8494 11.9999H17.8494C17.8494 14.0767 19.533 15.7603 21.6098 15.7603V13.7603ZM22.6098 17.5206L22.6098 14.7603L20.6098 14.7603L20.6098 17.5206L22.6098 17.5206ZM20.5056 19.6248C21.6677 19.6248 22.6098 18.6827 22.6098 17.5206H20.6098C20.6098 17.5781 20.5632 17.6248 20.5056 17.6248V19.6248ZM3.49391 19.6248H20.5056V17.6248H3.49391V19.6248ZM1.38974 17.5206C1.38974 18.6827 2.33181 19.6248 3.49391 19.6248V17.6248C3.43638 17.6248 3.38974 17.5781 3.38974 17.5206H1.38974ZM1.38974 14.7602V17.5206H3.38974V14.7602H1.38974ZM4.14999 11.9998C4.14999 12.972 3.36188 13.7601 2.38968 13.7602L2.3898 15.7602C4.46652 15.7601 6.14999 14.0765 6.14999 11.9998H4.14999ZM2.38968 10.2393C3.36188 10.2394 4.14999 11.0275 4.14999 11.9998H6.14999C6.14999 9.92301 4.46652 8.23946 2.3898 8.23934L2.38968 10.2393ZM1.38974 6.47892V9.23934H3.38974V6.47892H1.38974Z"
				fill="#7D828C"
			/>
			<circle cx="10.1188" cy="11.9997" r="1.10417" fill="#7D828C" />
			<circle cx="10.1188" cy="8.68717" r="1.10417" fill="#7D828C" />
			<circle cx="10.1188" cy="15.3122" r="1.10417" fill="#7D828C" />
		</g>
		<defs>
			<clipPath id="clip0_7049_53054">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0 -0.000244141)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default PromocodesIcon
