import * as React from "react"
import { SVGProps } from "react"

const DiscountTimeIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_7049_52976)">
			<path
				d="M7.83318 17.2292L16.1664 8.896"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<circle
				cx="8.66626"
				cy="10.5629"
				r="1.66646"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<circle
				cx="15.3337"
				cy="15.5621"
				r="1.66646"
				transform="rotate(-180 15.3337 15.5621)"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<rect
				x="3"
				y="3.87476"
				width="18"
				height="17.1"
				rx="1.8"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<path
				d="M6.6001 5.12494V2.43726"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M17.3999 5.12494V2.43726"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M12 5.12494V2.43726"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_7049_52976">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0 -0.000244141)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default DiscountTimeIcon
