import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";

import DiscountIcon from "../../../assets/_V2/svg/DiscountIcon";
import NoteIcon from "../../../assets/_V2/svg/NoteIcon";
import DiscountTimeIcon from "../../../assets/_V2/svg/DiscountTimeIcon";
import ClientGroupIcon from "../../../assets/_V2/svg/ClientGroupIcon";
import BonusIcon from "../../../assets/_V2/svg/BonusIcon";
import SpecialOffersIcon from "../../../assets/_V2/svg/SpecialOffersIcon";
import ComboIcon from "../../../assets/_V2/svg/ComboIcon";
import PromocodesIcon from "../../../assets/_V2/svg/PromocodesIcon";

import styles from "./styles.module.scss";
import ImageTabs from "../../../components/_V2/ImageTabs";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			category: "бустер продаж",
			title: (
				<>
					<span className={styles.title__mobileDesk}>
						Лояльность: 10 из 10
					</span>
					<span className={styles.title__mobile}>
						Лояльность: 10&nbsp;из&nbsp;10
					</span>
				</>
			),
			headerPlacement: "left",
			features: [
				{
					icon: <DiscountIcon />,
					name: "Фиксированные скидки",
					label: "Повышайте лояльность в любой непонятной ситуации",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-1-1_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-1-2_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize4,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Повышайте
								<br />
								лояльность в любой
								<br />
								непонятной
								<br />
								ситуации
							</div>
						</div>
					),
				},
				{
					icon: <DiscountTimeIcon />,
					name: "Скидки по расписанию",
					label: "Стимулируй продажи, когда это выгодно",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-2-1_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-2-2_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize3,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Стимулируй
								<br />
								продажи, когда это
								<br />
								выгодно
							</div>
						</div>
					),
				},
				{
					icon: <ClientGroupIcon />,
					name: "Группы клиентов",
					label: "Индивидуальные скидки для каждой группы",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-3-1_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-3-2_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize5,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Индивидуальные
								<br />
								скидки для каждой
								<br />
								группы
							</div>
						</div>
					),
				},
				{
					icon: <BonusIcon />,
					name: "Бонусные программы",
					label: "Бонусы —  это новая нефть!",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-4-1_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-4-2_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize1,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Бонусы — это
								<br />
								новая нефть!
							</div>
						</div>
					),
				},
				{
					icon: <SpecialOffersIcon />,
					name: "Спецпредложения",
					label: "Сделай опыт гостей особенным",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-5-1_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-5-2_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize5,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Сделай
								<br />
								опыт гостей
								<br />
								особенным
							</div>
						</div>
					),
				},
				{
					icon: <ComboIcon />,
					name: "Комбо",
					label: "Продавай то, что нужно",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-6-1_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-6-2_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize2,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Продавай то,
								<br />
								что нужно
							</div>
						</div>
					),
				},
				{
					icon: <PromocodesIcon />,
					name: "Промокоды",
					label: "Незаменимы для партнёрских программ",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-7-1_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-7-2_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize5,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Незаменимы для
								<br />
								партнёрских
								<br />
								программ
							</div>
						</div>
					),
				},
			],
		};
	}

	if (locale === "kz-KZ") {
		return {
			category: "САТУЛАР БУСТЕРІ",
			title: (
				<>
					<span className={styles.title__mobileDesk}>
						Адалдық: 10-нан 10
					</span>
					<span className={styles.title__mobile}>
						Адалдық: 10-нан 10
					</span>
				</>
			),
			headerPlacement: "left",
			features: [
				{
					icon: <DiscountIcon />,
					name: "Бекітілген жеңілдіктер",
					label: "Кез келген түсініксіз жағдайда адалдықты арттырыңыз",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис/Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-1-1_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-1-2_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize4,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Кез келген түсініксіз жағдайда адалдықты арттырыңыз
							</div>
						</div>
					),
				},
				{
					icon: <DiscountTimeIcon />,
					name: "Кесте бойынша жеңілдіктер",
					label: "Бұл тиімді болғанда сатуды ынталандырыңыз",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-2-1_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-2-2_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize3,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Бұл тиімді болғанда сатуды ынталандырыңыз
							</div>
						</div>
					),
				},
				{
					icon: <ClientGroupIcon />,
					name: "Клиенттер топтары",
					label: "Әр топ үшін жеке жеңілдіктер",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-3-1_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-3-2_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize5,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Әр топ үшін жеке жеңілдіктер
							</div>
						</div>
					),
				},
				{
					icon: <BonusIcon />,
					name: "Бонустық бағдарламалар",
					label: "Бонустар — бұл жаңа мұнай!",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-4-1_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-4-2_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize1,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Бонустар — бұл жаңа мұнай!
							</div>
						</div>
					),
				},
				{
					icon: <SpecialOffersIcon />,
					name: "Арнайы ұсыныстар",
					label: "Қонақтар тәжірибесін ерекше етіңіз",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-5-1_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-5-2_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize5,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Қонақтар тәжірибесін ерекше етіңіз
							</div>
						</div>
					),
				},
				{
					icon: <ComboIcon />,
					name: "Комбо",
					label: "Қажетті нәрсені сатыңыз",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-6-1_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-6-2_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize2,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Қажетті нәрсені сатыңыз
							</div>
						</div>
					),
				},
				{
					icon: <PromocodesIcon />,
					name: "Промокодтар",
					label: "Серіктестік бағдарламалар үшін таптырмайды",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-7-1_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-7-2_kz.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize5,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Серіктестік бағдарламалар үшін таптырмайды
							</div>
						</div>
					),
				},
			],
		};
	}

	if (locale === "ru-BY") {
		return {
			category: "бустер продаж",
			title: "Лояльнее некуда!",
			headerPlacement: "left",
			features: [
				{
					icon: <DiscountIcon />,
					name: "Фиксированные скидки",
					label: "Повышайте лояльность в любой непонятной ситуации",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-1-1_by.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-1-2_by.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize4,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Повышайте
								<br />
								лояльность в любой
								<br />
								непонятной
								<br />
								ситуации
							</div>
						</div>
					),
				},
				{
					icon: <DiscountTimeIcon />,
					name: "Скидки по расписанию",
					label: "Стимулируйте продажи, когда это выгодно",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-2-1_by.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-2-2_by.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize3,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Стимулируйте
								<br />
								продажи, когда это
								<br />
								выгодно
							</div>
						</div>
					),
				},
				{
					icon: <ClientGroupIcon />,
					name: "Группы клиентов",
					label: "Индивидуальные скидки для каждой группы",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-3-1_by.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-3-2_by.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize5,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Индивидуальные
								<br />
								скидки для каждой
								<br />
								группы
							</div>
						</div>
					),
				},
				{
					icon: <BonusIcon />,
					name: "Бонусные программы",
					label: "Бонусы —  это новая нефть!",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-4-1_by.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-4-2_by.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize1,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Бонусы — это
								<br />
								новая нефть!
							</div>
						</div>
					),
				},
				{
					icon: <SpecialOffersIcon />,
					name: "Спецпредложения",
					label: "Сделай опыт гостей особенным",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-5-1_by.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-5-2_by.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize5,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Сделай
								<br />
								опыт гостей
								<br />
								особенным
							</div>
						</div>
					),
				},
				{
					icon: <ComboIcon />,
					name: "Комбо",
					label: "Продавай то, что нужно",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-6-1_by.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-6-2_by.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize2,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Продавай то,
								<br />
								что нужно
							</div>
						</div>
					),
				},
				{
					icon: <PromocodesIcon />,
					name: "Промокоды",
					label: "Незаменимы для партнёрских программ",
					mediaContent: (
						<ImageTabs
							imagesData={[
								{
									name: "Бэк-офис",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-7-1_by.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
								{
									name: "Терминал",
									img: (
										<StaticImage
											className={styles.salesFeatures__image}
											src="./assets/marketing-sales-7-2_by.png"
											alt="схема столов в кафе"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
								},
							]}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.salesFeaturesBadge,
								styles.salesFeaturesBadgeSize5,
							)}
						>
							<div className={styles.salesFeaturesBadge__content}>
								<NoteIcon />
								Незаменимы для
								<br />
								партнёрских
								<br />
								программ
							</div>
						</div>
					),
				},
			],
		};
	}

	return {
		category: "бустер продаж",
		title: "Лояльнее некуда!",
		headerPlacement: "left",
		features: [
			{
				icon: <DiscountIcon />,
				name: "Фиксированные скидки",
				label: "Повышайте лояльность в любой непонятной ситуации",
				mediaContent: (
					<ImageTabs
						imagesData={[
							{
								name: "Бэк-офис",
								img: (
									<StaticImage
										className={styles.salesFeatures__image}
										src="./assets/marketing-sales-1-1.png"
										alt="схема столов в кафе"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
							},
							{
								name: "Терминал",
								img: (
									<StaticImage
										className={styles.salesFeatures__image}
										src="./assets/marketing-sales-1-2.png"
										alt="схема столов в кафе"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
							},
						]}
					/>
				),
				badge: (
					<div
						className={classNames(
							styles.salesFeaturesBadge,
							styles.salesFeaturesBadgeSize4,
						)}
					>
						<div className={styles.salesFeaturesBadge__content}>
							<NoteIcon />
							Повышайте
							<br />
							лояльность в любой
							<br />
							непонятной
							<br />
							ситуации
						</div>
					</div>
				),
			},
			{
				icon: <DiscountTimeIcon />,
				name: "Скидки по расписанию",
				label: "Стимулируйте продажи, когда это выгодно",
				mediaContent: (
					<ImageTabs
						imagesData={[
							{
								name: "Бэк-офис",
								img: (
									<StaticImage
										className={styles.salesFeatures__image}
										src="./assets/marketing-sales-2-1.png"
										alt="схема столов в кафе"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
							},
							{
								name: "Терминал",
								img: (
									<StaticImage
										className={styles.salesFeatures__image}
										src="./assets/marketing-sales-2-2.png"
										alt="схема столов в кафе"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
							},
						]}
					/>
				),
				badge: (
					<div
						className={classNames(
							styles.salesFeaturesBadge,
							styles.salesFeaturesBadgeSize3,
						)}
					>
						<div className={styles.salesFeaturesBadge__content}>
							<NoteIcon />
							Стимулируйте
							<br />
							продажи, когда это
							<br />
							выгодно
						</div>
					</div>
				),
			},
			{
				icon: <ClientGroupIcon />,
				name: "Группы клиентов",
				label: "Индивидуальные скидки для каждой группы",
				mediaContent: (
					<ImageTabs
						imagesData={[
							{
								name: "Бэк-офис",
								img: (
									<StaticImage
										className={styles.salesFeatures__image}
										src="./assets/marketing-sales-3-1.png"
										alt="схема столов в кафе"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
							},
							{
								name: "Терминал",
								img: (
									<StaticImage
										className={styles.salesFeatures__image}
										src="./assets/marketing-sales-3-2.png"
										alt="схема столов в кафе"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
							},
						]}
					/>
				),
				badge: (
					<div
						className={classNames(
							styles.salesFeaturesBadge,
							styles.salesFeaturesBadgeSize5,
						)}
					>
						<div className={styles.salesFeaturesBadge__content}>
							<NoteIcon />
							Индивидуальные
							<br />
							скидки для каждой
							<br />
							группы
						</div>
					</div>
				),
			},
			{
				icon: <BonusIcon />,
				name: "Бонусные программы",
				label: "Бонусы —  это новая нефть!",
				mediaContent: (
					<ImageTabs
						imagesData={[
							{
								name: "Бэк-офис",
								img: (
									<StaticImage
										className={styles.salesFeatures__image}
										src="./assets/marketing-sales-4-1.png"
										alt="схема столов в кафе"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
							},
							{
								name: "Терминал",
								img: (
									<StaticImage
										className={styles.salesFeatures__image}
										src="./assets/marketing-sales-4-2.png"
										alt="схема столов в кафе"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
							},
						]}
					/>
				),
				badge: (
					<div
						className={classNames(
							styles.salesFeaturesBadge,
							styles.salesFeaturesBadgeSize1,
						)}
					>
						<div className={styles.salesFeaturesBadge__content}>
							<NoteIcon />
							Бонусы — это
							<br />
							новая нефть!
						</div>
					</div>
				),
			},
			{
				icon: <SpecialOffersIcon />,
				name: "Спецпредложения",
				label: "Сделай опыт гостей особенным",
				mediaContent: (
					<ImageTabs
						imagesData={[
							{
								name: "Бэк-офис",
								img: (
									<StaticImage
										className={styles.salesFeatures__image}
										src="./assets/marketing-sales-5-1.png"
										alt="схема столов в кафе"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
							},
							{
								name: "Терминал",
								img: (
									<StaticImage
										className={styles.salesFeatures__image}
										src="./assets/marketing-sales-5-2.png"
										alt="схема столов в кафе"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
							},
						]}
					/>
				),
				badge: (
					<div
						className={classNames(
							styles.salesFeaturesBadge,
							styles.salesFeaturesBadgeSize5,
						)}
					>
						<div className={styles.salesFeaturesBadge__content}>
							<NoteIcon />
							Сделай
							<br />
							опыт гостей
							<br />
							особенным
						</div>
					</div>
				),
			},
			{
				icon: <ComboIcon />,
				name: "Комбо",
				label: "Продавай то, что нужно",
				mediaContent: (
					<ImageTabs
						imagesData={[
							{
								name: "Бэк-офис",
								img: (
									<StaticImage
										className={styles.salesFeatures__image}
										src="./assets/marketing-sales-6-1.png"
										alt="схема столов в кафе"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
							},
							{
								name: "Терминал",
								img: (
									<StaticImage
										className={styles.salesFeatures__image}
										src="./assets/marketing-sales-6-2.png"
										alt="схема столов в кафе"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
							},
						]}
					/>
				),
				badge: (
					<div
						className={classNames(
							styles.salesFeaturesBadge,
							styles.salesFeaturesBadgeSize2,
						)}
					>
						<div className={styles.salesFeaturesBadge__content}>
							<NoteIcon />
							Продавай то,
							<br />
							что нужно
						</div>
					</div>
				),
			},
			{
				icon: <PromocodesIcon />,
				name: "Промокоды",
				label: "Незаменимы для партнёрских программ",
				mediaContent: (
					<ImageTabs
						imagesData={[
							{
								name: "Бэк-офис",
								img: (
									<StaticImage
										className={styles.salesFeatures__image}
										src="./assets/marketing-sales-7-1.png"
										alt="схема столов в кафе"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
							},
							{
								name: "Терминал",
								img: (
									<StaticImage
										className={styles.salesFeatures__image}
										src="./assets/marketing-sales-7-2.png"
										alt="схема столов в кафе"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
							},
						]}
					/>
				),
				badge: (
					<div
						className={classNames(
							styles.salesFeaturesBadge,
							styles.salesFeaturesBadgeSize5,
						)}
					>
						<div className={styles.salesFeaturesBadge__content}>
							<NoteIcon />
							Незаменимы для
							<br />
							партнёрских
							<br />
							программ
						</div>
					</div>
				),
			},
		],
	};
};
